<template>
     <div>
        <CModalExtended
            color="dark"
            :closeOnBackdrop="false"
            :show.sync="$store.state.contenedores.modalCondicion"
            :title="tituloModal"
            :close-on-backdrop="false"
        >
            <CRow>
                <CCol sm="12" lg="12" >
                    <CSelect
                        :label="$t('label.statusName')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                        :value="formCondition.TpCargoStatusId"
                        :addInputClasses="{ 'is-invalid': $v.formCondition.TpCargoStatusId.$error }"
                        @blur="$v.formCondition.TpCargoStatusId.$touch()"
                        @input="formCondition.TpCargoStatusId = $event.target.value"
                        :options="optionList"
                        :invalid-feedback="errorMessage($v.formCondition.TpCargoStatusId)"
                        :is-valid="hasError($v.formCondition.TpCargoStatusId)"
                    >
                    </CSelect>
                </CCol>
               <CCol sm="12" lg="12" >
                    <CInput
                        :label="$t('label.name')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                        v-uppercase
                        :placeholder="$t('label.name')+ ' '+ $t('label.condition')"
                        v-model="$v.formCondition.TpCargoConditionName.$model"
                        @blur="$v.formCondition.TpCargoConditionName.$touch()"
                        :addInputClasses="{ 'is-invalid': $v.formCondition.TpCargoConditionName.$error }"
                        required 
                        :invalid-feedback="errorMessage($v.formCondition.TpCargoConditionName)"
                        :is-valid="hasError($v.formCondition.TpCargoConditionName)"
                    >
                    </CInput>
                </CCol>
               <CCol sm="12" lg="12" >
                    <CInput
                    :label="$t('label.acronym')"
                    addLabelClasses="required text-right"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                    v-uppercase
                    :placeholder="$t('label.acronym')"
                    v-model="$v.formCondition.TpCargoConditionCode.$model"
                     @blur="$v.formCondition.TpCargoConditionCode.$touch()"
                    :addInputClasses="{ 'is-invalid': $v.formCondition.TpCargoConditionCode.$error }"
                    required 
                    :invalid-feedback="errorMessage($v.formCondition.TpCargoConditionCode)"
                    :is-valid="hasError($v.formCondition.TpCargoConditionCode)"
                    maxLength="4"
                    >
                    </CInput>
                </CCol>
              <CCol sm="12" lg="12" >
                    <CSelect
                        :label="$t('label.status')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                        :is-valid="formCondition.FgActTpCargoCondition"
                        v-if="!idIndentification"
                        v-model="formCondition.FgActTpCargoCondition"
                        :value.sync="formCondition.FgActTpCargoCondition"
                        :options="selectOptions"
                    />
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateFormLoading"
                >
                    <div v-if="apiStateFormLoading">
                        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        {{$t('button.accept')}}
                    </div>

                    <div v-if="!apiStateFormLoading">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateFormLoading"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
                
            </div>
        </CModalExtended>
    </div>
</template>
<script>
    import ENUM from '@/_store/enum';
    import { FormCondition } from '@/_validations/contenedores/ContenedoresValidation';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { alertPropertiesHelpers} from '@/_helpers/funciones';
    import GeneralMixin from '@/_mixins/general';

    //data
    function data() {
        return {
            formCondition:{
                TpCargoConditionId:0,
                TpCargoStatusId:'',
                TpCargoConditionName:'',
                TpCargoConditionCode:'',
                FgActTpCargoCondition:true,
            },
            originalAct: '',
            tituloModal: this.$t('label.nueva')+' '+this.$t('label.condition'),
            myDataCombo:[],
        }
    }
    //methods
    function cerrarModal(){
        if(this.apiStateFormLoading)
            return
        this.$store.state.contenedores.modalCondicion = false;
    }
    function registerData(){
         try { 
                  this.$v.formCondition.$touch();
            if (this.$v.formCondition.$pending || this.$v.formCondition.$error)  {
                  throw this.$t('label.errorsPleaseCheck');
             } 

                    let { TpCargoConditionId,TpCargoStatusId,TpCargoConditionName,TpCargoConditionCode,FgActTpCargoCondition } = this.formCondition;
                    const { dispatch } = this.$store;
                    if(this.originalAct !== '' && TpCargoConditionId !== 0){
                        if(this.originalAct !== FgActTpCargoCondition){
                            this.$swal.fire(
                                alertPropertiesHelpers(this, {
                                    text: `${this.$t('label.changeStatusQuestion')+' '} ${TpCargoConditionName}?`,
                                })
                            ).then((result) => {
                                if (result.isConfirmed) {
                                    dispatch('contenedores/dataCondition', { TpCargoConditionId,TpCargoStatusId,TpCargoConditionName,TpCargoConditionCode,FgActTpCargoCondition });
                                }else {
                                    //this.$swal(this.$t('label.operationCancelled'));
                                }
                            })
                        }else{
                            dispatch('contenedores/dataCondition', { TpCargoConditionId,TpCargoStatusId,TpCargoConditionName,TpCargoConditionCode,FgActTpCargoCondition });
                        }
                    }else{
                        dispatch('contenedores/dataCondition', { TpCargoConditionId,TpCargoStatusId,TpCargoConditionName,TpCargoConditionCode,FgActTpCargoCondition });
                    }

         } catch (e) {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: "error"
            });
        }
    }
    //computed
    function idIndentification(){
        return this.formCondition.TpCargoConditionId === 0;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }

    function optionList(){
        if(this.myDataCombo.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataCombo.map(function(e){
                if(e.FgActTpCargoStatus){
                    chart.push({
                        value: e.TpCargoStatusId, 
                        label: e.TpCargoStatusName,
                    })
                }
            })
            return chart;
        }
    }

    function selectOptions (){
        return [
            { 
                value: true, 
                label: this.$t('label.ACTIVO')
            },
            { 
                value: false, 
                label: this.$t('label.INACTIVO')
            }
        ];
    }

    //watch
    function modalCondicion(newQuestion){
        if(newQuestion === false){
            this.formCondition.TpCargoConditionId=0;
            this.formCondition.TpCargoStatusId='';
            this.formCondition.TpCargoConditionName='';
            this.formCondition.TpCargoConditionCode='';
            
            this.originalAct = '';
            this.tituloModal = this.$t('label.nueva')+' '+this.$t('label.condition');
            this.formCondition.FgActTpCargoCondition=true;
            this.$nextTick(() => { this.$v.$reset() })
            this.$store.commit('contenedores/asignarid', 0);


        }else{
            this.tituloModal = this.$t('label.nueva')+' '+this.$t('label.condition');
            this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
            try {
                this.$http.ejecutar('GET', 'TpCargoStatus-list', { Filter: 'ALL' }, '')
                .then(
                    combo => {
                        if(combo.status === 200){
                            if(this.idState === 0){
                                this.myDataCombo = combo.data.data;
                                this.$store.state.contenedores.apiStateForm = ENUM.INIT;
                            }else{
                                const id = this.idState;
                                this.$http.ejecutar('GET', 'TpCargoCondition-by-id',{ TpCargoConditionId: id }, '' ).then(response => {
                                    if(response.status === 200){
                                        const Information = response.data.data;
                                        if(Information.length !== 0){
                                            this.tituloModal = this.$t('label.edit')+' '+this.$t('label.condition')+': '+Information[0].TpCargoConditionName;
                                            this.formCondition.TpCargoConditionId= Information[0].TpCargoConditionId;
                                            this.formCondition.TpCargoStatusId= Information[0].TpCargoStatusId;
                                            this.formCondition.TpCargoConditionName= Information[0].TpCargoConditionName;
                                            this.formCondition.TpCargoConditionCode= Information[0].TpCargoConditionCode;
                                            this.originalAct = Information[0].FgActTpCargoCondition;
                                            this.formCondition.FgActTpCargoCondition= Information[0].FgActTpCargoCondition;
                                            this.myDataCombo = combo.data.data;
                                            this.$store.state.contenedores.apiStateForm = ENUM.INIT;
                                        }
                                        this.$v.$touch();
                                    }else{
                                        this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                                        this.$store.state.contenedores.modalCondicion = false;
                                    }
                                }).catch(err => {
                                    this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                                    this.$store.state.contenedores.modalCondicion = false;
                                    this.$store.commit('contenedores/messageMutation', err);
                                });
                            }
                        }else{
                            this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                            this.$store.state.contenedores.modalCondicion = false;
                        }   
                    },
                    error => {
                        this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                        this.$store.state.contenedores.modalCondicion = false;
                        this.$store.commit('contenedores/messageMutation', error);
                        //dispatch('alert/error', error, { root: true });
                    }
                );
            } catch (error) {
                this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                this.$store.state.contenedores.modalCondicion = false;
                this.$store.commit('contenedores/messageMutation', err);
            }
        }
    }

    export default {
        name: 'modal-condition',
        data,
        validations(){ return FormCondition() },
        methods:{
            registerData,
            cerrarModal,
           // errorMessage,
        },
        mixins: [GeneralMixin ],
        directives: UpperCase,
        computed:{
            idIndentification,
            optionList,
            apiStateFormLoading,
            selectOptions,
            //tituloModal,
            ...mapState({
                idState: state=> state.contenedores.id,
                apiState: state => state.contenedores.apiState,
                apiStateForm: state => state.contenedores.apiStateForm,
                modalCondicion: state=> state.contenedores.modalCondicion,
            })
        },
        watch:{
            modalCondicion
        }
    }
</script>
