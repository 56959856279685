var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',{staticClass:"mt-3"},[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCol',{attrs:{"sm":"12"}},[_c('CRow',[_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                            content: _vm.$t('label.condition'),
                            placement: 'top-end'
                        }),expression:"{\n                            content: $t('label.condition'),\n                            placement: 'top-end'\n                        }"}],attrs:{"color":"add"},on:{"click":function($event){return _vm.activarModal()}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v("  "+_vm._s(_vm.$t('label.nueva'))+" ")],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                            content: _vm.$t('label.exportToExcel'),
                            placement: 'top-end'
                        }),expression:"{\n                            content: $t('label.exportToExcel'),\n                            placement: 'top-end'\n                        }"}],staticStyle:{"margin-left":"5px"},attrs:{"color":"excel"},on:{"click":function($event){return _vm.generarXLS(true)}}},[_c('CIcon',{attrs:{"name":"cil-file-excel"}}),_vm._v("  XLSX ")],1)],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"items":_vm.formatedItems,"fields":_vm.fields,"items-per-page":5,"pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"TpCargoConditionId",fn:function({ index }){return [_c('td',{staticClass:"center-cell"},[_vm._v(" "+_vm._s(index+1)+" ")])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"acciones",fn:function({item, index}){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: _vm.$t('label.edit')+' '+_vm.$t('label.condition'),
                                placement: 'top-end'
                            }),expression:"{\n                                content: $t('label.edit')+' '+$t('label.condition'),\n                                placement: 'top-end'\n                            }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.updateModal(item, index)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }