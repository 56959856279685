<template>
  <div ref="colorpicker">
   <CFormGroup
      v-bind="{ prepend, validFeedback, invalidFeedback,
          tooltipFeedback, description, wrapperClasses, class: computedClasses}"
      append="color"
    
    >
      <template #label>
        <slot name="label">
          <label v-if="label" :for="safeId" :class="labelClasses">
            {{label}}
          </label>
        </slot>
      </template>
      <template #input>
        <input
          v-bind="$attrs"
          :id="safeId"
          type="text"
          :class="inputClasses"
          :readonly="readonly || plaintext"
          @focus="showPicker()"
          v-model="colorValue"
        />
      </template>
      <template #append-content>
        <slot name="append-content">
          <span class="current-color" :style="'background-color: ' + colorValue" @click="togglePicker()"></span>
          <chrome-picker :value="colors" @input="updateFromPicker" v-if="displayPicker" />
        </slot>
      </template>

      <template 
        v-for="slot in $options.slots" 
        #[slot]
      >
        <slot :name="slot"></slot>
      </template>
    </CFormGroup>
  </div> 
 
</template>
<style>
  .vc-chrome {
      position: absolute;
      top: 35px;
      right: 0;
      z-index: 9;
  }
  .current-color {
      display: inline-block;
      width: 16px;
      height: 16px;
      background-color: #000;
      cursor: pointer;
  }
</style>
<script>
import { CFormGroup } from '@coreui/vue'
import { inputProps } from '@coreui/vue/src/components/form/form-props'
import * as allFormMixins from '@coreui/vue/src/components/form/form-mixins'
const mixins = Object.values(allFormMixins)
import { Chrome } from 'vue-color'

export const inputColorProps = Object.assign(
  {},
  inputProps,
  {
    color:{
      type: String,
      required: true,
      default: '#FFF',
    }
  }
)

export default {
  name: 'CInput',
  slots: [
    'prepend', 
    'prepend-content', 
    'label-after-input',
    'valid-feedback', 
    'invalid-feedback',
    'description'
  ],
  inheritAttrs: false,
  components: {
    'chrome-picker': Chrome,
    CFormGroup 
  },
  mixins,
  props: inputColorProps,
  // Html props: disabled, required, rows, cols, placeholder
  // {
  //   validFeedback: String,
  //   invalidFeedback: String,
  //   tooltipFeedback: Boolean,
  //   description: String,

  //   append: String,
  //   prepend: String,

  //   label: String,
  //   wasValidated: Boolean,
  //   isValid: {
  //     type: [Boolean, Function],
  //     default: null
  //   },
  //   addInputClasses: [String, Array, Object],
  //   addLabelClasses: [String, Array, Object],

  //   horizontal: [Boolean, Object],
  //   size: {
  //     type: String,
  //     validator: str => ['','sm','lg'].includes(str)
  //   },
  //   addWrapperClasses: [String, Array, Object],

  //   readonly: Boolean,
  //   plaintext: Boolean,
  //   value: [String, Number],
  //   lazy: {
  //     type: [Boolean, Number],
  //     default: 400
  //   },
  //   type: {
  //     type: String,
  //     default: 'text'
  //   }
  // },
  data () {
    return {
      state: this.value,
      syncTimeout: null,
      colors: {
        hex: '#000000',
      },
      colorValue: '#FFF',
      displayPicker: false,
    }
  },
  //watchValue mixin
  // watch: {
  //   value (val) {
  //     this.state = val
  //   }
  // },
  computed: {
    listeners () {
      const { input, change, ...listeners } = this.$listeners // eslint-disable-line no-unused-vars
      return listeners
    },
    // classesComputedProps mixin
    haveCustomSize () {
      return ['sm','lg'].includes(this.size)
    },
    // computedClasses () {
    //   return [
    //     'form-group',
    //     {
    //      'was-validated': this.wasValidated,
    //      'form-row': this.isHorizontal
    //     }
    //   ]
    // },
    // labelClasses () {
    //   return [
    //     this.addLabelClasses,
    //     {
    //       'col-form-label': this.isHorizontal,
    //       [this.horizontal.label || 'col-sm-3']: this.isHorizontal,
    //       [`col-form-label-${this.size}`]: this.haveCustomSize,
    //     }
    //   ]
    // },
    // customSizeClass () {
    //   if (this.haveCustomSize && !this.haveWrapper) {
    //     return `form-control-${this.size}`
    //   }
    // },
    // inputClasses () {
    //   return [
    //     this.inputClass || `form-control${this.plaintext ? '-plaintext' : ''}`,
    //     this.validationClass,
    //     this.addInputClasses,
    //     this.customSizeClass
    //   ]
    // }

    // validationComputedProps mixin
    // computedIsValid () {
    //   if (typeof this.isValid === 'function') {
    //     return this.isValid(this.state)
    //   }
    //   return this.isValid
    // },
    // validationClass () {
    //   if (typeof this.computedIsValid === 'boolean') {
    //     return this.computedIsValid ? 'is-valid' : 'is-invalid'
    //   }
    // }

    //wrapperComputedProps mixin
    // isHorizontal () {
    //   return Boolean(this.horizontal)
    // },
    // haveInputGroup () {
    //   return Boolean(
    //     this.tooltipFeedback || 
    //     this.append ||
    //     this.prepend || 
    //     this.$slots.append || 
    //     this.$slots.prepend || 
    //     this.$slots['append-content'] ||
    //     this.$slots['prepend-content']
    //   )
    // },
    haveWrapper () {
      return true || Boolean(this.addWrapperClasses || this.isHorizontal)
    },
    wrapperClasses () {
       if (this.haveWrapper) {
         return [
           this.addWrapperClasses,
           {
            [this.horizontal.input || 'col-sm-9'] : this.isHorizontal,
             'input-group': true,
             [`input-group-${this.size}`]: this.haveCustomSize
           }
         ]
       }
    }
  },
  methods: {
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(color) {
      if(color.slice(0, 1) == '#') {
        this.state = {
            hex: color
        };
      }
      else if(color.slice(0, 4) == 'rgba') {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g,'').split(','),
          hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
        this.state = {
          hex: hex,
          a: rgba[3],
        }
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.state = color;
      if(color.rgba.a == 1) {
          this.colorValue = color.hex;
      }
      else {
        this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
    },
    documentClick(e) {
      var el = this.$refs.colorpicker,
        target = e.target;
      if(el !== target && !el.contains(target)) {
        this.hidePicker()
      }
    }
  },
  watch: {
    colorValue(val) {
      if(val) {
        this.updateColors(val);
        this.$emit('input', val);
      }
    }
  },
}
</script>
